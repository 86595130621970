




import { Component, Vue } from 'vue-property-decorator'
import { store } from '@/store'
import { dispatchCheckLoggedIn, dispatchOidcToken } from '@/store/main/actions'
import { readIsLoggedIn } from '@/store/main/getters'
import { oidcLogin } from '@/interfaces/service/user/mainPage'
import { getRanterTreeData } from '@/interfaces/service/org/enterpriseMang'
const startRouteGuard = async (to, from, next) => {
  if ((to.fullPath as string) === '/login?oidc=false'
    || (to.path as string) === '/recover-password'
    || sessionStorage.getItem('adminLoggedIn') === 'true') {
    await dispatchCheckLoggedIn(store)
  } else {
    // 取得OIDC_TOKEN
    // 取得正常时设置token
    await dispatchOidcToken(store)
  }
  if (readIsLoggedIn(store)) {
    if ((to.fullPath as string) === '/login?oidc=false') {
      sessionStorage.setItem('adminLoggedIn', 'true')
    }
    if (to.path === '/login' || to.path === '/' || to.path === '') {
      await getRanterTreeData(store.state.main.userProfile ? store.state.main.userProfile.user_type : 1)
      if (store.state.main.enterprise.enterpriseData.length < 1) {
        next('/home-header/home-blank')
      } else {
        next('/home-header/home')
      }
    } else {
      next()
    }
  } else {
    if ((to.fullPath as string) === '/login?oidc=false' || (to.path as string) === '/recover-password') {
      next()
    } else {
      oidcLogin()
    }
  }
}

@Component
export default class Start extends Vue {
  public blankFlag : boolean = false
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next)
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next)
  }
  public mounted() {
    const content = this
    window.onresize = () => {
      // 定义窗口大小变更通知事件
      content.$store.state.main.screen.width = document.documentElement.clientWidth // 窗口宽度
      content.$store.state.main.screen.height = document.documentElement.clientHeight // 窗口高度
    }
    content.$store.state.main.messagePro = {}
  }
}
